<template>
  <Loading v-if="isInitLoading" />
  <div v-else>
    <div v-for="item in tail" :key="item.index">
      <div class="top">
        <img src="../../../static/image/left.png" @click="back" alt="" />
        <div class="order_text">确认订单</div>
      </div>
      <div class="box">
        <div class="shop">
          <img :src="item.showPic" alt="" />
          <div class="shop_text">
            <div class="shop_name">{{ item.goods_name }}</div>
            <div class="shop_serial">编号：#{{ item.goodsNumber }}</div>
          </div>
        </div>

        <div class="payment_box">
          <div class="payment_way">支付方式</div>

          <div class="balance_box">
            <div class="balance_text">
              <img src="../../../static/image/remaining.png" alt="" />
              <div class="surplus">余额</div>
              <div class="surplus_num">（剩余¥{{ anyUserinfo.money }}）</div>
            </div>
            <input
              type="radio"
              name="radio"
              value="radio_1"
              @click="dionoe"
              id=""
            />
          </div>
          <!-- <div class="balance_box">
            <div class="balance_text">
              <img src="../../../static/image/weixin.png" alt="" />
              <div class="surplus">微信</div>
            </div>
            <input type="radio" name="" id="" />
          </div> -->
          <div class="balance_box" v-if="payChannelInfo.pay_channel == 1">
            <div class="balance_text">
              <img src="../../../static/image/icon.png" alt="" />
              <div class="surplus">支付宝</div>
            </div>
            <input type="radio" name="radio" value="radio_1" @click="diotow" />
          </div>
          <!-- <div class="balance_box">
            <div class="balance_text">
              <img src="../../assets/images/bwallet.png" alt="" />
              <div class="surplus">银企钱包</div>
            </div>
            <input type="radio" name="radio" value="radio_1" @click="diotow1" />
          </div> -->
          <!-- <div class="balance_box">
            <div class="balance_text">
              <img src="../../../static/image/icon@2x.png" alt="" />
              <div class="surplus">银行卡</div>
            </div>
            <div style="display: flex; align-items: center">
              <div style="font-size: 5px; color: #666666">
                建设银行储蓄卡（7734）
              </div>
              <img
                style="width: 6px; height: 10px; display: block"
                src="../../../static/image/right.png"
                alt=""
              />
            </div>
          </div> -->
        </div>

        <div class="message_box" style="padding: 5px 0">
          <div class="purchase">
            <div class="purchase_name">购买信息</div>
            <div class="purchase_messy">{{ item.nickName }}</div>
          </div>

          <div class="purchase">
            <div class="purchase_name">购买时间</div>
            <div class="purchase_messy">{{ item.created_at }}</div>
          </div>

          <div class="purchase">
            <div class="purchase_name">订单编号</div>
            <div class="purchase_messy">
              <span style="font-size: 13px">{{ item.consignment }}</span>
              <img
                @click="copy(item.consignment)"
                src="../../../static/image/fuzhi@2x.png"
                alt=""
              />
            </div>
          </div>
          <div class="purchase">
            <div class="purchase_name">订单金额</div>
            <div class="purchase_num">￥{{ item.pay_price }}</div>
          </div>
        </div>

        <!-- <div class="message_box">
          <div class="purchase" @click="isShowSign = true">
            <div class="purchase_name">使用优惠券</div>
            <div class="purchase_num">
              <i class="right"></i>
            </div>
          </div>
        </div> -->

        <div class="pay" @click="payment(item)">确认支付</div>
      </div>

      <van-overlay :show="show">
        <div class="wap">
          <div class="wap_affirm">
            <div @click="noe" class="cha">×</div>
            <div>请输入交易密码</div>
            <div class="forgotPwd" @click="$router.push('/forgotPwd')">
              忘记密码
            </div>
          </div>
          <div class="wap_form">订单金额</div>
          <div class="wap_num">￥{{ item.pay_price }}</div>
          <div class="wap_balance">
            余额<span
              >(￥
              {{ anyUserinfo.money }}
              )</span
            >
          </div>
          <!-- 密码输入框 -->
          <van-password-input
            :value="value"
            :error-info="errorInfo"
            :gutter="10"
            :focused="showKeyboard"
            @focus="showKeyboard = true"
            @input="specifiName($event)"
          />
          <!-- 数字键盘 -->
          <van-number-keyboard
            :show="showKeyboard"
            @input="onInput"
            @delete="onDelete"
            @blur="showKeyboard = true"
          />
        </div>
      </van-overlay>
    </div>

    <van-overlay :show="mystery">
      <div class="mystery_nei">
        <div class="mystery_block">
          <div class="mystery_text">当前订单待支付,是否取消支付?</div>
          <div class="mystery_but">
            <div class="mystery_one" @click="cannal">确认取消</div>
            <div class="mystery_tow" @click="mystery = false">继续支付</div>
          </div>
        </div>
      </div>
    </van-overlay>

    <div class="selectPayType" v-if="isShowSign">
      <div class="selectPayTypeBox"></div>
      <div class="bg">
        <div class="content">请在APP内查看卡券</div>
        <div class="btnGroup">
          <div class="unCompleted" @click="isShowSign = false">取消</div>
          <div class="completed" @click="openApp">去下载app</div>
        </div>
      </div>
    </div>

    <!-- 银企钱包 -->
    <div class="selectPayType" v-if="showBWallet">
      <div class="selectPayTypeBox"></div>
      <div class="bg">
        <div class="content">请在APP内使用银企钱包支付</div>
        <div class="btnGroup">
          <div class="unCompleted" @click="showBWallet = false">取消</div>
          <div class="completed" @click="openApp">去下载app</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import loading from '../../components/loading/loading.vue';
import { isSafari } from '../../utils/tools';
import { Dialog } from 'vant';
export default {
  components: { loading },
  data() {
    return {
      value: '', //密码输入框
      errorInfo: '',
      showKeyboard: true,
      showBWallet: false,
      show: false,
      mystery: false,
      id: '',
      token: '',
      tail: [],
      anyUserinfo: {},
      radio: '1',
      num: '',
      arr: '',
      radio_1: '', //单选框的值
      orderNo: '',
      isInitLoading: false,
      payChannelInfo: {},
      isShowSign: false,
    };
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.cb);
  },
  methods: {
    diotow1(e) {
      e.preventDefault();
      this.showBWallet = true;
    },
    openApp() {
      window.open('https://v.hxnft.shop/appDownload/index.html');
      this.isShowSign = false;
      this.showBWallet = false;
    },
    copy(url) {
      //新建一个文本框
      let oInput = document.createElement('input');
      //赋值给文本框
      oInput.value = url;
      document.body.appendChild(oInput);
      // 选择对象;
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand('Copy');
      //复制完成删除掉输入框
      oInput.remove();
      this.$toast('复制成功');
    },
    payChannel() {
      this.$api.payChannel().then((e) => {
        if (e.code === 0) {
          this.payChannelInfo = e.data || {};
        }
      });
    },
    cb() {
      if (document.hidden) {
        // 页面被挂起
        console.log('页面被挂起');
      } else {
        console.log('页面呼出');
        // 页面呼出 支付宝购买后返回，刷新次数接口
        const query = {
          token: localStorage.getItem('token'),
          order_no: this.orderNo,
        };
        this.$api.loop(query).then((e) => {
          if (e.code != 100000) return this.$toast(e.msg);
          this.info = JSON.stringify(e.data);
          if (e.data.status === 2) {
            // this.$router.replace('/Allorder?type=2');
            this.$router.replace(
              `/unpaid?id=${this.tail[0].id}&orderType=2&isShowTimeOut=1`,
            );
            return this.$toast('支付成功');
          } else {
            // this.$router.back();
            // this.$router.replace('/Allorder?type=2');
            this.$router.replace(`/unpaid?id=${this.tail[0].id}&orderType=2`);
            return this.$toast('支付失败');
          }
        });
      }
    },
    // 确认支付
    async payment(item) {
      if (this.radio_1 == 'balance') {
        if (this.anyUserinfo.is_pay_password != 1) {
          return this.$toast('请前往安全中心设置交易密码');
        }
        this.show = true;
      } else if (this.radio_1 == 'alipay') {
        const res = await this.$api.payChannel();
        if (res.code == 0) {
          if (res.data.pay_channel == 2) {
            this.payChannelInfo = res.data || {};
            return this.$toast('请使用余额支付');
          }
        }
        let params = {
          token: localStorage.getItem('token'),
          pay_channel: 'alipay_wap',
          order_no: this.tail[0].order_no,
        };
        this.$api.payJuhe(params).then((res) => {
          if (res.code == 100000) {
            this.orderNo = item.order_no;
            if (isSafari()) {
              window.location.href = res.data.expend.pay_info;
            } else {
              this.$router.replace({
                path: '/Pay',
                query: {
                  payUrl: res.data.expend.pay_info,
                  orderNo: item.order_no,
                  id: this.tail[0].id,
                  orderType: 2,
                },
              });
            }
          } else {
            this.$toast(res.data.error_msg);
          }
        });
      } else {
        this.$toast('请选择支付方式');
      }
    },
    noe() {
      this.show = false;
      this.value = '';
    },
    specifiName() {
      // var that = this;
    },
    // 余额支付
    onInput(key) {
      if (this.value.length > 5) return;
      this.value = (this.value + key).slice(0, 6);
      if (this.value.length === 6) {
        let num = this.tail[0].order_number;
        let params = {
          token: localStorage.getItem('token'),
          pay_channel: 'balance', // 余额支付
          // pay_channel: "alipay_wap", 支付宝支付
          order_no: this.tail[0].order_no,
          payPassword: this.value,
        };
        this.$api.payJuhe(params).then((res) => {
          if (res.code == 100000) {
            this.$toast(res.msg);
            // this.$router.push('/user');
            this.$router.replace(
              `/unpaid?id=${this.tail[0].id}&orderType=2&isShowTimeOut=1`,
            );
          } else {
            this.$toast(res.msg);
            this.value = '';
          }
        });
      } else {
        this.errorInfo = '';
      }
    },
    onDelete() {
      this.value = this.value.slice(0, this.value.length - 1);
    },

    // 选择支付方式
    dionoe(e) {
      if (Number(this.anyUserinfo.money) < Number(this.tail[0].pay_price)) {
        e.preventDefault();
        Dialog.confirm({
          title: '提示',
          message: '余额不足请前往充值,是否前往?',
          cancelButtonText: '取消',
          confirmButtonText: '前往',
        }).then(() => {
          this.$router.push('/topUp');
        });
        return;
      }
      this.radio_1 = 'balance';
      let yin = document.querySelector('.pay');
      yin.style.color = '#ffffff';
      yin.style.backgroundColor = '#0754d3';
    },
    diotow() {
      this.radio_1 = 'alipay';
      let yin = document.querySelector('.pay');
      yin.style.color = '#ffffff';
      yin.style.backgroundColor = '#0754d3';
    },
    back() {
      this.mystery = true;
      // this.$router.back();
    },
    // 取消
    cannal() {
      let params = {
        token: localStorage.getItem('token'),
        id: this.tail[0].id,
      };
      this.$api.cancelUserOrder(params).then((res) => {
        if (res.code == 0) {
          this.$toast(res.msg);
          this.$router.back();
        }
      });
    },
  },
  mounted() {
    this.isInitLoading = false;
    if (isSafari()) {
      document.addEventListener('visibilitychange', this.cb);
    }
    this.id = this.$route.query.id;
    this.payChannel();
    // // 确认订单
    // let reg = {
    //   id: this.id,
    //   token: localStorage.getItem('token'),
    // };
    // this.$api.goodsorderDetail(reg).then(
    //   (res) => {
    //     this.isInitLoading = false;
    //     if (res.code == 100000) {
    //       this.tail = res.data;
    //     } else {
    //       this.$router.back();
    //       this.$toast(res.msg);
    //     }
    //   },
    //   () => {
    //     this.isInitLoading = false;
    //   },
    // );

    try {
      this.tail = [JSON.parse(this.$route.query.item)];
    } catch (error) {
      this.tail = [];
    }

    //余额信息
    let params = {
      token: localStorage.getItem('token'),
    };
    this.$api.anyUserinfo(params).then((res) => {
      if (res.code == 0) {
        this.anyUserinfo = res.data;
      }
    });
  },
};
</script>

<style lang="less" scoped>
/deep/.van-password-input {
  position: relative;
  margin-bottom: 20px;
}
.wap_affirm {
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  div {
    flex: 2;
    text-align: center;
    font-size: 16px;
  }
  .cha {
    flex: 1;
    color: #666666;
    text-align: left;
    font-size: 30px;
  }
  .forgotPwd {
    flex: 1;
    text-align: right;
    font-size: 14px;
    color: rgb(rgb(7 84 211));
  }
}
.wap_balance {
  font-size: 10px;
  margin-bottom: 30px;
  span {
    color: #999999;
  }
}
.wap_form {
  font-size: 10px;
  margin-top: 20px;
}
.wap_num {
  height: 19px;
  font-size: 18px;
  font-weight: 400;
  color: #e76810;
  line-height: 20px;
  margin-top: 10px;
}
.wap {
  padding-top: 10px;
  width: 100%;
  background-color: #ffffff;
  height: 70%;
  position: absolute;
  bottom: 0;
  border-radius: 10px 10px 0px 0px;
  text-align: center;
}
.block {
  width: 120px;
  height: 120px;
  background-color: #fff;
}
.top {
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  img {
    width: 11px;
    height: 20px;
  }
  .order_text {
    font-size: 18px;
    margin-left: 38%;
  }
}

.box {
  background-color: #f3f4f8;
  width: 100%;
  height: 100vh;
  padding-top: 15px;
  .shop {
    background-color: #ffffff;
    display: flex;
    width: 331px;
    align-items: center;
    padding-top: 12px;
    border-radius: 5px;
    padding-bottom: 15px;
    padding-left: 10px;
    margin-left: 17px;
    img {
      width: 50px;
      margin-right: 13px;
      height: 50px;
    }
    .shop_text {
      .shop_name {
        font-size: 14px;
      }
      .shop_serial {
        font-size: 12px;
        color: #999999;
      }
    }
  }
  .payment_box {
    margin-top: 15px;
    border-radius: 5px;
    width: 331px;
    margin-left: 17px;
    background-color: #ffffff;
    padding-left: 10px;
    padding-bottom: 10px;
    .payment_way {
      font-size: 14px;
      padding-top: 18px;
    }
    .balance_box {
      display: flex;
      justify-content: space-between;
      padding-right: 10px;
      .balance_text {
        display: flex;
        align-items: center;
        line-height: 30px;
      }
      img {
        display: block;
        width: 21px;
        height: 20px;
        margin-right: 12px;
      }
      .surplus {
        font-size: 14px;
        color: #333333;
      }
      .surplus_num {
        color: #999999;
        font-size: 11px;
      }
    }
  }
  .message_box {
    margin-top: 10px;
    width: 341px;
    background-color: #ffffff;
    margin-left: 17px;
    border-radius: 5px;
    .purchase {
      display: flex;
      line-height: 32px;
      padding-left: 10px;
      justify-content: space-between;
      padding-right: 10px;
      font-size: 14px;
      .purchase_name {
        font-size: 14px;
        color: #999999;
      }
      .purchase_messy {
        color: #333333;
        display: flex;
        align-items: center;
        span {
        }
        img {
          display: block;
          width: 12px;
          height: 12px;
          margin-left: 10px;
        }
      }
      .purchase_num {
        color: #e76810;
        display: flex;
        align-items: center;
        .right {
          display: block;
          position: relative;
          width: 8px;
          height: 8px;
          border-top: 1px solid #aaa;
          border-right: 1px solid #aaa;
          transform: rotate(45deg);
        }
      }
    }
  }
  .pay {
    width: 200px;
    height: 40px;
    background-color: #eeeeee;
    color: #666666;
    font-size: 15px;
    text-align: center;
    line-height: 40px;
    border-radius: 5px;
    margin-top: 45px;
    margin-left: 22%;
  }
}

.mystery_nei {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .mystery_block {
    background-color: #fff;
    width: 250px;
    height: 150px;
    border-radius: 10px;
    .mystery_text {
      font-size: 16px;
      text-align: center;
      line-height: 50px;
      margin-top: 20px;
    }
    .mystery_but {
      display: flex;
      font-size: 14px;
      justify-content: space-around;
      margin-top: 20px;
      .mystery_tow {
        height: 30px;
        line-height: 30px;
        width: 80px;
        text-align: center;
        background-color: black;
        color: #ffffff;
      }
      .mystery_one {
        height: 30px;
        line-height: 30px;
        width: 80px;
        text-align: center;
        // background-color: ;
        border: 1px black solid;
      }
    }
  }
}

/deep/.van-password-input__security li {
  border: 2px #666666 solid;
  border-radius: 5px;
}
.selectPayType {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 99999;
  .selectPayTypeBox {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
  .bg {
    width: 80%;
    height: 160px;
    background: #ffffff;
    border-radius: 5px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .content {
      font-size: 16px;
      color: #666666;
      margin: 35px 0 35px;
      text-align: center;
    }
    .btn {
      width: 60%;
      height: 35px;
      margin: 0 auto;
      background: #0754d3;
      border-radius: 3px;
    }
    .btnGroup {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px;
      div {
        background: #0754d3;
        border-radius: 3px;
        font-size: 14px;
        color: #fff;
        width: 90px;
        height: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid transparent;
        &.unCompleted {
          border: 1px solid #0754d3;
          background: transparent;
          color: #0754d3;
        }
      }
    }
  }
}
</style>
